import axios from './api.request'
// 地图key
export const mapKey = '1c675b2abf70b92c1968e8330b96acf5'
//地图秘钥
export const SafeJsCode = 'cb153466bcfe923d64049d187a3345b7'
export const AMapWebServiceKey = '0e11539ed74c4cc831c09699360942a6' // 高德地图web服务key
// 地图中心点
export const center = {
    lng: 102.762737,
    lat: 28.979411,
    zoom: 6.5
}
// 达州市地理坐标为北纬30°75′-32°07′，东经106°94′-108°06′。
// 文件上传
export const uploadImage = '/api/SiteManagement/UploadImage'

// 登录
// export const login = (data) => {
//     return axios.request({
//         url: '/api/UserInfo/UserLogin',
//         data,
//         method: 'post'
//     })
// }
export const weather = (params) => { //天气
    return axios.request({
        url: 'https://restapi.amap.com/v3/weather/weatherInfo',
        params,
        method: 'get'
    })
}

export const ElectricQuantity = (params) => { //电站电量排行
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/ElectricQuantity',
        params,
        method: 'get'
    })
}
// export const ChargingPileKwhRanking = (params) => { //电桩电量排行
//     return axios.request({
//         noToast: false,
//         url: '/api/BigScreen/ChargingPileKwhRanking',
//         params,
//         method: 'get'
//     })
// }
export const SiteOrderNumRaning = (params) => { //站对应的订单 桩 数量排行
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/SiteOrderNumRaning',
        params,
        method: 'get'
    })
}

export const GetSiteChargingMemKwhTotle = (params) => { //统计电站，桩，电量，钱
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetSiteChargingMemKwhTotle',
        params,
        method: 'get'
    })
}
export const OperatorRankingStat = (params) => { //运营商电量排行统计
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/OperatorRankingStat',
        params,
        method: 'get'
    })
}
export const GetOrderInfoTotal = (params) => { //订单非法率，失败率，临停率，完成率，企业用户，运营商用户，普通用户，每日新增用户统计
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetOrderInfoTotal',
        params,
        method: 'get'
    })
}
export const ChargingCapacityStat = (params) => { //充电量统计
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/ChargingCapacityStat',
        params,
        method: 'get'
    })
}
export const UseOfStateDetails = (params) => { //电桩状态
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/UseOfStateDetails',
        params,
        method: 'get'
    })
}
export const UserAnalysis = (params) => { //用户分析
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/UserAnalysis',
        params,
        method: 'get'
    })
}

export const getMap = (params) => { //桩地图
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetSiteChargingList',
        params,
        method: 'get'
    })
}

export const siteStat = (params) => { //站点统计
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/SitePilesPage',
        params,
        method: 'get'
    })
}

export const elePile = (params) => { //站点统计
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/ChargePliesPage',
        params,
        method: 'get'
    })
}


export const dayMoney = (params) => { //站点统计
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/ChargingCapacityStat',
        params,
        method: 'get'
    })
}

export const curev = (params) => { //站点统计
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/UserAddDailyAnalysis',
        params,
        method: 'get'
    })
}

// 充电站电量详细信息
export const siteInfo = (data) => {
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetBatteryRankingBySiteid',
        data,
        method: 'post'
    })
}

// 电量详细查询
export const electricNum = (params) => {
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetSiteElectricByTime',
        params,
        method: 'get'
    })
}

// 运营商详细查询
export const operatorEle = (params) => {
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetOperatorSiteElectricDetail',
        params,
        method: 'get'
    })
}

// 充电站电量详细信息
export const chargeState = (data) => {
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetChargeStat',
        data,
        method: 'post'
    })
}

// 用户注册方式详情
export const registerType = (params) => {
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetUserRegStat',
        params,
        method: 'get'
    })
}

// 用户分析详情
export const userCout = (params) => {
    return axios.request({
        noToast: true,
        url: '/api/BigScreen/GetChargingUserAnalysisDetail',
        params,
        method: 'get'
    })
}